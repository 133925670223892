<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <h2 class="text-white pb-4 uppercase text-xl font-extrabold">Leden uitnodigen</h2>
        </div>
    </DashboardWrapper>  
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";

export default {
    name: "ledenInvite",
    components: {
        DashboardWrapper,
    },
}
</script>

<style>
</style>